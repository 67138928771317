import React from "react";
import Services from "./services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeartbeat } from "@fortawesome/free-solid-svg-icons";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import { faSchool } from "@fortawesome/free-solid-svg-icons";
import { faShieldAlt } from "@fortawesome/free-solid-svg-icons";

import imag1 from "../../images/0.jpg";
import imag2 from "../../images/01.jpg";
import imag3 from "../../images/02.jpg";
import imag4 from "../../images/03.jpg";
import { Helmet } from "react-helmet";
const icons = {
  icon1: faHeartbeat,
  icon2: faShieldAlt,
  icon3: faSchool,
  icon4: faCoins,

  size: "4x",
};
const modicons = {
  icon1: "heartbeat",
  icon2: "shield alternate",
  icon3: "child",
  icon4: "money bill alternate",
  icon5: "leanpub",
  icon6: "shield alternate",
  icon7: "law",
};
const bcolour = "white";
const classes = {
  maindiv: "p-5",
  container: "",
  title: "gray",
  subtitle: "",
  span: "omg-lines",
  row: "row",
  col: " py-2 col-12 col-sm-12 col-md-6",
  coldiv: "p-2",
  image: "",
  header: "",
  parag: "p-3 text-justify",
};

const services = [
  {
    title: "ΥΓΕΙΑ",
    imag: imag1,
    alt: "Aσφάλιση υγείας",
    modout: (
      <>
        <p>
          Αποκτήστε ισόβια ασφάλιση υγείας με εγγύηση των όρων για εσάς και για
          όλη σας την οικογένεια.
        </p>
        <hr></hr>
        <p>
          Ασφάλιση που μπορεί να σας συνοδεύει σε όλη τη διάρκεια της ζωής σας.
        </p>
        <hr></hr>
        <p>Κάλυψη 100% στην Ελλάδα και παντού στον κόσμο.</p>
      </>
    ),
    sicon: (
      <FontAwesomeIcon icon={icons.icon1} size={icons.size} className="" />
    ),
    modtitle: "ΥΓΕΙΑ",
    modicon: modicons.icon1,
    modinside: (
      <>
        <Helmet>
          <title>Υπηρεσίες - Υγεία</title>
          <meta
            name="description"
            content="πειδή λοιπόν η υγεία μας είναι η πρώτη προτεραιότητα, εμπιστευτείτε την στην πρώτη ασφαλιστική, την Εθνική Ασφαλιστική. "
          />
          <meta
            name="keywords"
            content="Ασφάλεια, υγεία, ασφαλιστική σύμβουλος, εθνική ασφαλιστική, οικογένια, Φωτεινή Βαρδή, Συνταξιοδοτικό Πρόγραμμα, Συνταξη, Γ.Φουφόπουλου"
          />
        </Helmet>
        <p>
          Πέρα και πάνω από όλα τα ανθρώπινα αγαθά υπάρχει ένα που είναι το
          πολυτιμότερο, η υγεία.
        </p>
        <p>
          Επειδή λοιπόν η υγεία μας είναι η πρώτη προτεραιότητα, εμπιστευτείτε
          την στην πρώτη ασφαλιστική, την Εθνική Ασφαλιστική. Η Εθνική
          Ασφαλιστική σας προσφέρει μια σειρά προγραμμάτων υγείας, ικανών να
          καλύψουν όλες τις πιθανές ανάγκες σας για τη φροντίδα της υγείας, της
          δικής σας και της οικογένειάς σας.
        </p>

        <p>
          Επιλέγοντας ένα από τα ασφαλιστικά προγράμματα Υγείας της Εθνικής
          Ασφαλιστικής εξασφαλίζετε:
        </p>
        <ul>
          <li>
            Ασφάλιση που μπορεί να σας συνοδεύει σε όλη τη διάρκεια της ζωής
            σας.
          </li>
          <li>
            Ελεύθερη επιλογή νοσοκομείου στην Ελλάδα και στο εξωτερικό, ανάλογα
            με το πρόγραμμα της επιλογής σας και &nbsp;ειδικά προνόμια σε
            περίπτωση νοσηλείας στα Συμβεβλημένα Νοσηλευτικά Ιδρύματα.
          </li>
          <li>
            Επιλογή θέσης νοσηλείας ανάλογα με το πρόγραμμα της επιλογής σας.
          </li>
          <li>
            Ασφάλιση και των αγαπημένων σας προσώπων, συζύγου και παιδιών, με τα
            ίδια προνόμια και παροχές.
          </li>
          <li>
            Σημαντικές οικογενειακές εκπτώσεις ασφαλίστρου κατά ποσοστό 20% για
            τον/την σύζυγο, 30% για το 1ο παιδί, 40% για το 2ο παιδί, 50% για το
            3ο παιδί και δωρεάν ασφάλιση από το 4ο παιδί και άνω.
          </li>
          <li>
            Συμπληρωματική λειτουργία με δημόσιο φορέα και οποιοδήποτε ομαδικό
            ασφαλιστήριο.
          </li>
          <li>
            Πρόσθετες Υπηρεσίες Υγείας μέσω Δικτύου Ιατρών, Δικτύου
            Οδοντιατρείων, Δικτύου Διαγνωστικών Κέντρων, Συνεργασιών με
            νοσοκομεία,&nbsp;για τους κατόχους προγράμματος ασφάλισης υγείας της
            Εθνικής Ασφαλιστικής, το οποίο καλύπτει και έξοδα εξωνοσοκομειακών
            διαγνωστικών εξετάσεων.
          </li>
        </ul>
      </>
    ),
    link: "https://www.ethniki-asfalistiki.gr/defaultMRB.aspx?Page=health",
  },
  {
    title: "ΠΡΟΣΤΑΣΙΑ ΖΩΗΣ",
    imag: imag4,
    alt: "ΠΡΟΣΤΑΣΙΑ ΖΩΗΣ",
    modout: (
      <>
        <p>
          Προσφέρετε ένα δώρο αγάπης προστασίας στην οικογένεια με μηδενικό
          κόστος για εσάς.
        </p>
        <hr></hr>
        <p> Σχεδιασμένο για την ικανοποίηση των πραγματικών σας επιθυμιών.</p>
        <hr></hr>
        <p>Eξασφάλιση για εσάς και τους δικούς σας από ό,τι κι αν συμβεί.</p>
      </>
    ),
    sicon: (
      <FontAwesomeIcon icon={icons.icon2} size={icons.size} className="" />
    ),
    modtitle: "ΠΡΟΣΤΑΣΙΑ ΖΩΗΣ",
    modicon: modicons.icon2,
    modinside: (
      <>
        <Helmet>
          <title>Υπηρεσίες - Προστασία ζωής</title>
          <meta
            name="description"
            content="Η δημιουργία, η χαρά, η απόλαυση της ζωής μαζί με τους δικούς σας ανθρώπους είναι ό,τι πιο όμορφο."
          />
          <meta
            name="keywords"
            content="Ασφάλεια, υγεία, ασφαλιστική σύμβουλος, εθνική ασφαλιστική, οικογένια, Φωτεινή Βαρδή, Συνταξιοδοτικό Πρόγραμμα, Συνταξη, Γ.Φουφόπουλου"
          />
        </Helmet>
        <p>
          Η δημιουργία, η χαρά, η απόλαυση της ζωής μαζί με τους δικούς σας
          ανθρώπους είναι ό,τι πιο όμορφο. Σε μια εποχή που τα πάντα αλλάζουν
          διαρκώς, είναι ανεκτίμητο το να μπορείτε να αντιμετωπίσετε την
          ανασφάλεια και την αβεβαιότητα για το αύριο και να «προστατεύσετε» τη
          ζωή τη δική σας, αλλά και της οικογενείας σας. Τα Προγράμματα
          Προστασίας Ζωής της Εθνικής Ασφαλιστικής εξασφαλίζουν εσάς και την
          οικογένειά σας από οποιοδήποτε απρόβλεπτο γεγονός συμβεί στη ζωή σας
          και δημιουργήσει ανάγκες που δεν είχατε προβλέψει.
        </p>
        <strong>Προστασία Ζωής με την Εθνική Ασφαλιστική:</strong>
        <ul>
          <li>
            Μπορείτε να επιλέξετε ανάμεσα σε πλήθος προγραμμάτων και καλύψεων,
            σχεδιασμένων με γνώμονα την ικανοποίηση των πραγματικών σας αναγκών,
            που εξασφαλίζουν εσάς και τους δικούς σας από ό,τι κι αν συμβεί.
          </li>
          <li>Απολαμβάνετε γρήγορες υπηρεσίες και διαδικασίες.</li>
          <li>
            Απολαμβάνετε την εξασφάλιση που σας εγγυάται η ηγετική θέση, η
            εμπειρία, η φερεγγυότητα, και η αξιοπιστία που αποπνέει το όνομα της
            Εθνικής Ασφαλιστικής μέλος του Ομίλου της Εθνικής Τράπεζας.
          </li>
        </ul>
      </>
    ),
    link: "https://www.ethniki-asfalistiki.gr/defaultMRB.aspx?page=life",
  },
  {
    title: "ΓΙΑ ΤΟ ΠΑΙΔΙ",
    imag: imag2,
    alt: "Για το παιδί",
    modout: (
      <>
        <p>
          Αποταμιεύστε με προγραμματισμό από σήμερα και εξασφαλίστε το μέλλον
          των παιδιών.
        </p>
        <hr></hr>
        <p>
          Δώστε την ευκαιρία στο παιδί σας να επιλέξει το πανεπιστήμιο και τις
          σπουδές που επιθυμεί.
        </p>
        <hr></hr>
        <p>Δείτε το παιδί σας να απογειώνει τα όνειρα του .</p>
      </>
    ),
    sicon: (
      <FontAwesomeIcon icon={icons.icon3} size={icons.size} className="" />
    ),

    modtitle: "ΓΙΑ ΤΟ ΠΑΙΔΙ",
    modicon: modicons.icon3,
    modinside: (
      <>
        <Helmet>
          <title>Υπηρεσίες - Για το Παιδί</title>
          <meta
            name="description"
            content="Η εξασφάλιση και το μέλλον των παιδιών είναι το πιο σημαντικό μέλημα για τους γονείς, από τη στιγμή που θα έρθουν στη ζωή."
          />
          <meta
            name="keywords"
            content="Ασφάλεια, υγεία, ασφαλιστική σύμβουλος, εθνική ασφαλιστική, οικογένια, Φωτεινή Βαρδή, Συνταξιοδοτικό Πρόγραμμα, Συνταξη, Γ.Φουφόπουλου"
          />
        </Helmet>
        <p>
          Η εξασφάλιση και το μέλλον των παιδιών είναι το πιο σημαντικό μέλημα
          για τους γονείς, από τη στιγμή που θα έρθουν στη ζωή. Από τα πρώτα
          τους χρόνια, νοιάζεστε για το μέλλον, την υποστήριξη που θα
          χρειασθούν, τις σπουδές, την επαγγελματική τους δραστηριότητα και
          φυσικά την υγεία τους. Στη σημερινή εποχή, οι ανάγκες της σύγχρονης
          διαβίωσης είναι λογικό να σας οδηγούν στην επιθυμία να τους παρέχετε
          από τώρα όλα τα εφόδια για να ανταποκριθούν στις απαιτήσεις του
          μέλλοντος. Τα Παιδικά Προγράμματα της Εθνικής Ασφαλιστικής είναι
          πολύτιμα, αφού καλύπτουν τις ανάγκες ενός παιδιού σε ορισμένα στάδια
          της ζωής του και δεν επιτρέπουν να σταθούν εμπόδια στο μέλλον του,
          γεγονότα απρόβλεπτα που μπορεί να ανατρέψουν την καθημερινότητα (π.χ.
          ατυχία στη δουλειά, οικονομική κρίση, κάποια ασθένεια, ένα μοιραίο
          γεγονός ή ένα άτυχο περιστατικό).
        </p>
        <strong>
          Επιλέγοντας ένα από τα Προγράμματα της Εθνικής Ασφαλιστικής για το
          Παιδί έχετε:
        </strong>
        <ul>
          <li>
            Την εξασφάλιση ενός ποσού για το παιδί τη στιγμή ακριβώς που θα το
            έχει ανάγκη.
          </li>
          <li>
            Τη δυνατότητα ολοκληρωμένης ασφαλιστικής προστασίας του παιδιού και
            του μέλλοντός του από ό,τι κι αν συμβεί.
          </li>
          <li>
            Την εξασφάλιση που σας εγγυάται η ηγετική θέση, η εμπειρία, η
            φερεγγυότητα, η αξιοπιστία που αποπνέει το όνομα της Εθνικής
            Ασφαλιστικής και τη σιγουριά του Ομίλου της Εθνικής Τράπεζας, μέλος
            του οποίου είναι η Εθνική Ασφαλιστική.
          </li>
        </ul>
      </>
    ),
    link: "https://www.ethniki-asfalistiki.gr/defaultMRB.aspx?page=child",
  },
  {
    title: "ΣΥΝΤΑΞΗ",
    imag: imag3,
    alt: "ΣΥΝΤΑΞΗ",
    modout: (
      <>
        <p>
          Απολαύστε τη ζωή που ονειρεύεστε τελειώνοντας με τις εργασιακές
          υποχρεώσεις σας.
        </p>
        <hr></hr>
        <p>
          {" "}
          Διατηρήστε ένα καλό βιοτικό επίπεδο ισοβίως, χωρίς να αλλάξετε
          συνήθειες και τρόπο ζωής.
        </p>
        <hr></hr>
        <p>Επιλέξτε εσείς το Full Life Plan που σάς ταιριάζει</p>
      </>
    ),
    sicon: (
      <FontAwesomeIcon icon={icons.icon4} size={icons.size} className="" />
    ),
    modtitle: "ΣΥΝΤΑΞΗ",
    modicon: modicons.icon4,
    modinside: (
      <>
        <Helmet>
          <title>Υπηρεσίες - Σύνταξη</title>
          <meta
            name="description"
            content="Όταν σταματήσετε να δουλεύετε έχετε την ευκαιρία να απολαύσετε την ζωή κάνοντας πολλά πράγματα που δεν προλαβαίνατε πριν."
          />
          <meta
            name="keywords"
            content="Ασφάλεια, υγεία, ασφαλιστική σύμβουλος, εθνική ασφαλιστική, οικογένια, Φωτεινή Βαρδή, Συνταξιοδοτικό Πρόγραμμα, Συνταξη, Γ.Φουφόπουλου"
          />
        </Helmet>
        <p>
          Όταν σταματήσετε να δουλεύετε έχετε την ευκαιρία να απολαύσετε την ζωή
          κάνοντας πολλά πράγματα που δεν προλαβαίνατε πριν. Παρά τις σημαντικές
          αλλαγές στο ασφαλιστικό τοπίο της χώρας και την αβεβαιότητα που
          επικρατεί όσον αφορά τα μελλοντικά εισοδήματα, εσείς μπορείτε να
          πάρετε το μέλλον στα χέρια σας και να φροντίσετε γι’ αυτό όσο είναι
          νωρίς, εξασφαλίζοντας ένα υψηλό βιοτικό επίπεδο και μετά τη
          συνταξιοδότησή σας. Σήμερα, όλο και περισσότεροι Έλληνες
          συνειδητοποιούν ότι τα Συνταξιοδοτικά Προγράμματα της ιδιωτικής
          ασφάλισης θα τους επιτρέψουν να χαρούν τη ζωή τότε ακριβώς που θα
          έχουν στη διάθεσή τους τον πολυπόθητο χρόνο. Η Εθνική Ασφαλιστική,
          κατανοώντας απόλυτα τις οικονομικές συνθήκες και τις απαιτήσεις του
          σήμερα αλλά και τις ανησυχίες για το αύριο, σχεδιάζει και προσφέρει
          λύσεις σε κάθε άνθρωπο που ενδιαφέρεται πραγματικά για την κάλυψη των
          συνταξιοδοτικών του αναγκών.
        </p>
        <strong>
          Με τα Συνταξιοδοτικά Προγράμματα της Εθνικής Ασφαλιστικής εξασφαλίζετε
          μια καλύτερη ζωή, γιατί μπορείτε:
        </strong>
        <ul>
          <li>
            Να διατηρήσετε ένα καλό βιοτικό επίπεδο ισοβίως, χωρίς να αλλάξετε
            συνήθειες και τρόπο ζωής όταν συνταξιοδοτηθείτε.
          </li>
          <li>Να προγραμματίσετε το μέλλον σας σήμερα.</li>
          <li>Να εξασφαλίσετε τα αγαπημένα σας πρόσωπα.</li>
          <li>Να έχετε σύνταξη και σε σύντομο χρονικό διάστημα.</li>
          <li>
            Να επιλέξετε εσείς πώς θα καταβάλλετε τα ασφάλιστρά σας, το
            επιθυμητό ποσό μηνιαίας σύνταξης και την ηλικίας έναρξης καταβολής
            της σύνταξης (άνω των 50).
          </li>
          <li>
            Να επιλέξετε ένα από τα πρωτοποριακά προγράμματα που ανταποκρίνονται
            ακριβώς στα νέα δεδομένα της εποχής.
          </li>
        </ul>
      </>
    ),
    link: "https://www.ethniki-asfalistiki.gr/defaultMRB.aspx?page=pension",
  },
];
const Table = () => {
  return (
    <div id="services">
      <Services color={bcolour} services={services} classes={classes} />
    </div>
  );
};
export default Table;
